<template>
  <div
    class="modal fade"
    id="delModal"
    role="dialog"
    aria-labelledby="basicModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">削除確認</h4>
        </div>
        <div class="modal-body">
          <label>{{ message }}</label>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
          <button type="button" class="btn btn-danger" @click="modalSubmit">削除</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'delete-dialog',
  props: {
    selectedData: {
      type: Object,
      default: null,
    }
  },
  computed: {
    message() {
      if (this.selectedData) {
        const name = this.selectedData.display_name ? this.selectedData.display_name
          : this.selectedData.name
        return `${name}を削除しますか？`
      }
    }
  },
  methods: {
    modalSubmit() {
      this.$emit('submit');
    },
  }
}
</script>

