<template>
  <div>
    <div id="toolbar">
      <a :href="newUrl" class="btn btn-primary" v-if="!noCreate">
        <i class="fas fa-plus-circle"></i>新規登録
      </a>
    </div>
    <bootstrap-table :data="myData" :options="myOptions" :columns="exColumns" />
    <delete-dialog :selected-data="selectedData" @submit="modalSubmit" />
  </div>
</template>

<script>
  import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.min.js'
  import 'bootstrap-table/dist/locale/bootstrap-table-ja-JP.min.js'
  import DeleteDialog from './DeleteDialog'

  export default {
    name: 'customize-table',
    props: {
      data: {
        type: String,
        default: '{}'
      },
      columns: {
        type: String,
        default: '{}',
      },
      routePrefix: {
        type: String,
        default: '',
      },
      noCreate: {
        type: Boolean,
        default: false,
      },
      role: {
        type: String,
        default: '',
      },
    },
    components: {
      BootstrapTable,
      DeleteDialog,
    },
    computed: {
      myData() {
        return JSON.parse(this.data)
      },
      newUrl() {
        return this.routePrefix + '/create'
      },
      exColumns() {
        const fileldsInfo = JSON.parse(this.columns)
        const dataFieldsInfo = fileldsInfo.map(elem => {
          return {
            field: elem.name,
            title: elem.title,
            sortable: true,
            filterControl: elem.filterControl,
          }
        })
        const ownRole = this.role
        const isDataTypeUser = this.routePrefix === '/admin/users'
        return [
          {
            field: 'actionL',
            title: '編集',
            align: 'center',
            width: '140px',
            clickToSelect: false,
            formatter: function(e, value, row){
              return `
                <button
                  class="btn btn-sm btn-primary mr-1 edit"
                  ${isDataTypeUser && value.role < ownRole ? 'disabled' : ''}>
                  <i class="fas fa-edit"></i>
                </button>`
            },
            events: {
              'click .edit': (e, value, row) => {
                return window.location.assign(`${this.routePrefix}/${row.id}/edit`)
              },
            }
          },
          ...dataFieldsInfo,
          {
            field: 'actionR',
            title: '削除',
            align: 'center',
            width: '140px',
            clickToSelect: false,
            formatter: function(e, value, row){
              return `
                <button
                  class="btn btn-sm btn-danger mr-1 destroy"
                  data-toggle="modal"
                  data-target="#delModal"
                  ${isDataTypeUser && value.role < ownRole ? 'disabled' : ''}>
                  <i class="fas fa-trash"></i>
                </button>`
            },
            events: {
              'click .destroy': (e, value, row) => {
                this.selectedData = row
              }
            }
          }
        ]
      }
    },
    data() {
      return {
        myOptions: {
          search: true,
          pagination: true,
          paginationPreText: '前へ',
          paginationNextText: '次へ',
          pageSize: 100,
          pageList: [10, 50, 100, 'All'],
          showColumns: true,
          showPrint: false,
          showExport: false,
          exportDataType: 'all',
          filterControl: true,
          toolbar: '#toolbar',
          clickToSelect: true,
          idField: 'id',
          selectItemName: 'id',
        },
        selectedData: null,
      }
    },
    methods: {
      modalSubmit() {
        const id = this.selectedData.id
        axios.delete(`${this.routePrefix}/${id}`)
        .then(response => {
          if (response.status == 200) {
            window.location.replace(`${this.routePrefix}`)
            return
          } else {
            alert("削除に失敗しましたX。")
            return
          }
        }).catch(function(error){
          console.log('err', error)
          alert("削除に失敗しましたY。")
          return
        })
      }
    }
  }
</script>
